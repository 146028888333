import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconOpen.module.scss';
import { TIconProps } from 'src/types/icons';

type IconOpenProps = Omit<TIconProps, 'iconId'> & SVGProps<SVGSVGElement>;

export const IconOpen: FunctionComponent<IconOpenProps> = ({
  className,
  width = 24,
  height = 24,
  fill,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M5.40001 20.4C4.90501 20.4 4.48126 20.2237 4.12876 19.8712C3.77626 19.5187 3.60001 19.095 3.60001 18.6V5.39998C3.60001 4.90498 3.77626 4.48123 4.12876 4.12873C4.48126 3.77623 4.90501 3.59998 5.40001 3.59998H11.1C11.355 3.59998 11.5688 3.68573 11.7413 3.85723C11.9138 4.02873 12 4.24123 12 4.49472C12 4.74822 11.9138 4.96248 11.7413 5.13748C11.5688 5.31248 11.355 5.39998 11.1 5.39998H5.40001V18.6H18.6V12.9C18.6 12.645 18.6858 12.4312 18.8573 12.2587C19.0288 12.0862 19.2413 12 19.4948 12C19.7483 12 19.9625 12.0862 20.1375 12.2587C20.3125 12.4312 20.4 12.645 20.4 12.9V18.6C20.4 19.095 20.2238 19.5187 19.8713 19.8712C19.5188 20.2237 19.095 20.4 18.6 20.4H5.40001ZM18.6 6.67498L10.3 14.975C10.1167 15.1583 9.90834 15.2458 9.67501 15.2375C9.44167 15.2291 9.23334 15.1333 9.05001 14.95C8.86667 14.7666 8.77501 14.5541 8.77501 14.3125C8.77501 14.0708 8.86667 13.8583 9.05001 13.675L17.325 5.39998H15.3C15.045 5.39998 14.8313 5.31423 14.6588 5.14273C14.4863 4.97123 14.4 4.75873 14.4 4.50523C14.4 4.25173 14.4863 4.03748 14.6588 3.86248C14.8313 3.68748 15.045 3.59998 15.3 3.59998H19.5C19.755 3.59998 19.9688 3.68622 20.1413 3.85872C20.3138 4.03122 20.4 4.24498 20.4 4.49998V8.69998C20.4 8.95498 20.3143 9.16873 20.1428 9.34123C19.9713 9.51373 19.7588 9.59998 19.5053 9.59998C19.2518 9.59998 19.0375 9.51373 18.8625 9.34123C18.6875 9.16873 18.6 8.95498 18.6 8.69998V6.67498Z"
        fill={fill || 'currentColor'}
      />
    </svg>
  );
};

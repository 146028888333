import { Typography } from 'antd';
import { IconAttachment } from 'src/components/icons/attachment/IconAttachment';
import styles from './File.module.scss';

const { Link } = Typography;

type TFileProps = {
  value: string;
};

const File = ({ value }: TFileProps) => {
  return (
    <div className={styles.component}>
      <IconAttachment width="16" height="16" className={styles.icon} />
      <Link className={styles.text} href={value}>{value}</Link>
    </div>
  );
};

export default File;

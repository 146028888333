import Highlight from 'react-highlight-words';

type THighlighterProps = {
  value: string;
  search: string;
};

const Highlighter = ({ value, search }: THighlighterProps) => {
  return (
    <Highlight
      searchWords={[search]}
      textToHighlight={value}
      highlightStyle={{ backgroundColor: 'transparent', color: '#cdab04' }}
    />
  );
};

export default Highlighter;

import { Form } from 'antd';
import { useState } from 'react';
import {
  REFRESH_TOKEN,
  UserContextActions,
  useUserContext,
} from 'src/contexts/user-context';
import useIdentity from './use-identity';

const useLoginForm = () => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [postError, setPostError] = useState('');

  const { validateCompany, loginUser } = useIdentity();

  const [form] = Form.useForm();

  const company: string = Form.useWatch('company', form);
  const username: string = Form.useWatch('username', form);
  const password: string = Form.useWatch('password', form);

  const [savedCompany, setSavedCompany] = useState('');

  const { userContextDispatch } = useUserContext();

  const submit = () => {
    form
      .validateFields()
      .then(async () => {
        setLoading(true);
        setPostError('');

        if (step === 1) {
          const result = await validateCompany(company.trim());
          if (result.errors?.response?.status === 404) {
            setPostError('Company not found.');
          } else if (result.result?.status === 200) {
            setSavedCompany(company);
            setStep(2);
          } else if (result.errors?.response?.data) {
            setPostError(result.errors.response.data as string);
          }
        } else {
          const result = await loginUser({
            company: savedCompany.trim(),
            email: username.trim(),
            password: password.trim(),
          });

          if (result.errors?.response) {
            setPostError('Wrong email or password.');
          } else if (result.result?.status === 200) {
            userContextDispatch({
              type: UserContextActions.SET_USER,
              value: result.result.data.accessToken,
            });
            localStorage.setItem(
              REFRESH_TOKEN,
              result.result.data.refreshToken
            );
          }
        }
        setLoading(false);
      })
      .catch((errr) => {
        console.error(errr);
      });
  };

  return {
    step,
    setStep,
    loading,
    postError,
    form,
    company,
    username,
    password,
    submit,
  };
};

export default useLoginForm;

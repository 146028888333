import { Typography, theme } from 'antd';
import styles from './Steps.module.scss';
import clsx from 'clsx';

const { Text } = Typography;

export type TStepsProps = {
  step: number;
  steps: number;
  className?: string;
};

const Steps = ({ step, steps, className }: TStepsProps) => {
  const {
    token: { colorTextTertiary },
  } = theme.useToken();
  return (
    <Text
      className={clsx([styles.component, className])}
      style={{ color: colorTextTertiary }}
    >
      Step {step} of {steps}
    </Text>
  );
};

export default Steps;

import {
  createContext,
  type FunctionComponent,
  useContext,
  Dispatch,
  useReducer,
  useMemo,
  PropsWithChildren,
  useState,
  useEffect,
} from 'react';
import Modal from 'src/components/modal/Modal';
import { message, theme } from 'antd';
import { IconInfo } from 'src/components/icons/info/IconInfo';
import styles from 'src/components/modal/Modal.module.scss';
import useProducts from 'src/hooks/use-products';
import { BROADCASTNAME } from 'src/constants/broadcast';

export enum ActionType {
  ARCHIVE = 'ARCHIVE',
}

type TModal = {
  title: string;
  description: string;
  actionText: string;
  action: ActionType;
  dataId?: string;
  dataName?: string;
};

type TModalContextState = {
  modal: TModal | null;
};

export enum ModalContextActions {
  SHOW_MODAL = 'SHOW_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
}

export type TModalContextStateAction =
  | {
      type: ModalContextActions.CLOSE_MODAL;
    }
  | {
      type: ModalContextActions.SHOW_MODAL;
      value: TModal;
    };

export type TModalContextValue = {
  modalContextState: TModalContextState;
  modalContextDispatch: Dispatch<TModalContextStateAction>;
};

const modalContextReducer = (
  state: TModalContextState,
  action: TModalContextStateAction
) => {
  const { type } = action;

  switch (type) {
    case ModalContextActions.CLOSE_MODAL: {
      return {
        modal: null,
      };
    }
    case ModalContextActions.SHOW_MODAL: {
      if (typeof action.value === 'undefined') {
        return state;
      }

      return {
        modal: action.value,
      };
    }

    default:
      return state;
  }
};

const modalContexInitialState: TModalContextState = {
  modal: null,
};

const ModalContext = createContext<TModalContextValue>({
  modalContextState: modalContexInitialState,
  modalContextDispatch: () => null,
});

type ModalContextProviderProps = PropsWithChildren<{
  initialState?: TModalContextState;
}>;

export const ModalContextProvider: FunctionComponent<
  ModalContextProviderProps
> = ({ initialState = modalContexInitialState, children }): JSX.Element => {
  const [messageApi, contextHolder] = message.useMessage();
  const { deleteProduct } = useProducts();

  const {
    token: { colorInfo },
  } = theme.useToken();

  const [modalContextState, modalContextDispatch] = useReducer(
    modalContextReducer,
    { ...initialState }
  );

  const contextValue = useMemo(
    () => ({ modalContextState, modalContextDispatch }),
    [modalContextState, modalContextDispatch]
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        modalContextDispatch({
          type: ModalContextActions.CLOSE_MODAL,
        });
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  const modal = modalContextState.modal;

  const secondaryAction = () => {
    modalContextDispatch({
      type: ModalContextActions.CLOSE_MODAL,
    });
  };

  const primaryAction = async () => {
    if (!modal) {
      return;
    }

    setLoading(true);

    switch (modal.action) {
      case ActionType.ARCHIVE:
        if (modal.dataId) {
          await deleteProduct(modal.dataId);
          messageApi.info({
            content: `Product "${modal.dataName}" archived`,
            icon: (
              <IconInfo
                className={styles.info}
                height="16"
                width="16"
                fill={colorInfo}
              />
            ),
          });
          const broadcast = new BroadcastChannel(BROADCASTNAME);
          broadcast.postMessage('login');
          broadcast.close();
        }
        break;
    }

    setLoading(false);
    secondaryAction();
  };

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      {contextHolder}
      {!!modal && (
        <Modal
          title={modal.title}
          description={modal.description}
          primaryActionTitle={modal.actionText}
          primaryAction={primaryAction}
          secondaryAction={secondaryAction}
          secondaryActionTitle="Cancel"
          primaryIsLoading={loading}
        />
      )}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error(
      '"ModalContext" context is not in scope. Use this hook in a component that is wrapped with the <ModalContextProvider /> component.'
    );
  }

  return context;
};

import { Form } from 'antd';
import type { Rule } from 'antd/es/form';
import { PropsWithChildren } from 'react';
import { IconError } from 'src/components/icons/error/IconError';

export type TFormItemProps = PropsWithChildren<{
  label: string;
  name: string;
  value: string;
  rules?: Rule[];
  hasFeedback?: boolean;
}>;

const FormItem = ({
  children,
  label,
  name,
  rules,
  value,
  hasFeedback = false,
}: TFormItemProps) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={rules}
      hasFeedback={
        hasFeedback &&
        !value && {
          icons: () => ({
            error: <IconError />,
          }),
        }
      }
    >
      {children}
    </Form.Item>
  );
};

export default FormItem;

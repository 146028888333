import { HTTP, useNetworkContext } from 'src/contexts/network-context';
import {
  TGetAccessTokenResponse,
  TGetPermissionsResponse,
  TLoginParams,
  TLoginResponse,
} from 'src/types/backend';

const useIdentity = () => {
  const { fetch } = useNetworkContext();

  const loginUser = async (data: TLoginParams) => {
    const result = await fetch<TLoginResponse>({
      url: '/identity/login',
      method: HTTP.POST,
      data,
    });

    return result;
  };

  const getPermissions = async () => {
    const result = await fetch<TGetPermissionsResponse>({
      url: '/identity/me/roles',
      method: HTTP.GET,
    });

    return result;
  };

  const validateCompany = async (company: string) => {
    const result = await fetch<string>({
      url: '/identity/company/exist',
      method: HTTP.POST,
      data: {
        company,
      },
    });

    return result;
  };

  const getAccessToken = async (refreshToken: string) => {
    const result = await fetch<TGetAccessTokenResponse>({
      url: '/identity/refresh',
      method: HTTP.POST,
      data: {
        refreshToken,
      },
    });
    return result;
  };

  return { loginUser, validateCompany, getAccessToken, getPermissions };
};

export default useIdentity;

import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconSort.module.scss';
import { TIconProps } from 'src/types/icons';

export enum SortOrder {
  ASC = 'ascend',
  DESC = 'descend',
}

type IconSortProps = Omit<TIconProps, 'iconId'> &
  SVGProps<SVGSVGElement> & {
    order: SortOrder | null;
  };

export const IconSort: FunctionComponent<IconSortProps> = ({
  className,
  width = 24,
  height = 24,
  order,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M8.19995 9.50005C8.04995 9.50005 7.92912 9.45005 7.83745 9.35005C7.74578 9.25005 7.69995 9.13338 7.69995 9.00005C7.69995 8.96672 7.74995 8.85005 7.84995 8.65005L11.475 5.02505C11.5583 4.94172 11.6416 4.88338 11.725 4.85005C11.8083 4.81672 11.9 4.80005 12 4.80005C12.1 4.80005 12.1916 4.81672 12.275 4.85005C12.3583 4.88338 12.4416 4.94172 12.525 5.02505L16.15 8.65005C16.2 8.70005 16.2375 8.75422 16.2625 8.81255C16.2875 8.87088 16.3 8.93338 16.3 9.00005C16.3 9.13338 16.2541 9.25005 16.1625 9.35005C16.0708 9.45005 15.95 9.50005 15.8 9.50005H8.19995Z"
        fill={order === SortOrder.ASC ? styles.active : styles.default}
      />

      <path
        d="M11.475 18.975L7.84995 15.35C7.79995 15.3 7.76245 15.2458 7.73745 15.1875C7.71245 15.1292 7.69995 15.0667 7.69995 15C7.69995 14.8667 7.74578 14.75 7.83745 14.65C7.92912 14.55 8.04995 14.5 8.19995 14.5H15.8C15.95 14.5 16.0708 14.55 16.1625 14.65C16.2541 14.75 16.3 14.8667 16.3 15C16.3 15.0333 16.25 15.15 16.15 15.35L12.525 18.975C12.4416 19.0583 12.3583 19.1167 12.275 19.15C12.1916 19.1833 12.1 19.2 12 19.2C11.9 19.2 11.8083 19.1833 11.725 19.15C11.6416 19.1167 11.5583 19.0583 11.475 18.975Z"
        fill={order === SortOrder.DESC ? styles.active : styles.default}
      />
    </svg>
  );
};

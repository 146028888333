import { PropsWithChildren, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LOGIN_PATHS, PROTECTED_PATHS } from 'src/constants/login-paths';
import {
  REFRESH_TOKEN,
  UserContextActions,
  useUserContext,
} from 'src/contexts/user-context';
import useIdentity from 'src/hooks/use-identity';
import { Permission } from 'src/types/general';
import { ProtectedRoutes, Routes } from 'src/types/routes';

const AuthWrapper = ({ children }: PropsWithChildren) => {
  const restoredRef = useRef(false);

  const navigate = useNavigate();
  const location = useLocation();

  const {
    userContextState: { accessToken },
    userContextDispatch,
  } = useUserContext();

  const { getPermissions } = useIdentity();

  useEffect(() => {
    if (restoredRef.current) {
      return;
    }
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);

    const validateRoutes = async () => {
      if (
        (!!accessToken || !!refreshToken) &&
        LOGIN_PATHS.includes(location.pathname)
      ) {
        console.log(
          `%c⏩\tUser is logged in. Redirecting to the dashboard.`,
          'color: #876800;'
        );
        navigate(ProtectedRoutes.DASHBOARD);
        return;
      }

      if (
        !accessToken &&
        !refreshToken &&
        PROTECTED_PATHS.includes(location.pathname)
      ) {
        console.log(
          `%c⏩\tUser is logged out. Redirecting to the sign in.`,
          'color: #876800;'
        );

        navigate(Routes.SIGNIN);
        return;
      }
    };

    validateRoutes();
  }, [accessToken, location.pathname]);

  useEffect(() => {
    const fetchPermissions = async () => {
      const result = await getPermissions();
      if (result.result?.data) {
        const permissonsList = result.result?.data;
        userContextDispatch({
          type: UserContextActions.SET_PERMISSIONS,
          value: {
            isAdmin: permissonsList
              .map((el) => el.name)
              .includes(Permission.ADMIN),
            isAdvancedUser: permissonsList
              .map((el) => el.name)
              .includes(Permission.ADVANCED_USER),
          },
        });
      }
    };
    if (accessToken) {
      fetchPermissions();
    }
  }, [accessToken]);

  return <>{children}</>;
};

export default AuthWrapper;

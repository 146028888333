import { GetProp, TablePaginationConfig, TableProps } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { useEffect, useMemo, useState } from 'react';
import {
  TMappedRecord,
  getMappedProductData,
  getProductColumnSetting,
} from 'src/utils/map-product-table';
import useDebouncer from './use-debouncer';
import { useModalContext } from 'src/contexts/modal-context';
import useProducts from './use-products';
import { BROADCASTNAME } from 'src/constants/broadcast';
import { useUserContext } from 'src/contexts/user-context';

export interface ITableParams {
  pagination?: TablePaginationConfig;
  sortField?: SorterResult<any>['field'];
  sortOrder?: SorterResult<any>['order'];
  filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

const TABLE_WIDTH = 1625;

const useProductsList = () => {
  const { getProducts } = useProducts();

  const {
    userContextState: { isAdmin, isAdvancedUser },
  } = useUserContext();
  const { modalContextDispatch } = useModalContext();

  const [data, setData] = useState<TMappedRecord[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const [tableParams, setTableParams] = useState<ITableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: 'name',
    sortOrder: 'ascend',
  });

  const isActionAllowed = isAdmin || isAdvancedUser;

  const columns = useMemo(() => {
    const res = getProductColumnSetting(
      modalContextDispatch,
      isActionAllowed,
      debouncedSearch
    );
    return res;
  }, [tableParams, isActionAllowed, debouncedSearch]);

  // Note: debounces search value
  useDebouncer(setDebouncedSearch, search);

  const fetchProducts = async () => {
    setLoading(true);
    const result = await getProducts({
      ...tableParams,
      search: debouncedSearch.length >= 3 ? debouncedSearch : '',
    });
    if (result.result?.data.data) {
      setData(getMappedProductData(result.result.data.data));
      setTotal(result.result.data.totalCount);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchProducts();
    const broadcast = new BroadcastChannel(BROADCASTNAME);
    broadcast.onmessage = fetchProducts;
    return () => {
      broadcast.close();
    };
  }, [tableParams]);

  useEffect(() => {
    if (debouncedSearch.length >= 3 || !debouncedSearch.length) {
      fetchProducts();
    }
  }, [debouncedSearch]);

  const handleTableChange: TableProps<any>['onChange'] = (
    pagination,
    filters,
    sorter
  ) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      sortField: Array.isArray(sorter)
        ? undefined
        : sorter.order
          ? sorter.field
          : undefined,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  return {
    handleTableChange,
    setSearch,
    loading,
    columns,
    width: TABLE_WIDTH,
    data,
    total,
    debouncedSearch,
  };
};

export default useProductsList;

import {
  Alert,
  Button,
  ConfigProvider,
  Form,
  type FormInstance,
  Input,
  Typography,
  theme,
} from 'antd';

import styles from './SignInForm.module.scss';
import FormItem from './item/FormItem';

const { Text } = Typography;

export type TSignInFormProps = {
  step: number;
  form: FormInstance<unknown>;
  company: string;
  username: string;
  password: string;
  submit: () => void;
  postError: string;
  loading: boolean;
};

const SignInForm = ({
  step,
  form,
  company,
  username,
  password,
  submit,
  postError,
  loading,
}: TSignInFormProps) => {
  const {
    token: { marginXS, margin, colorErrorBg, colorErrorBorder },
  } = theme.useToken();
  return (
    <div>
      <div style={{ gap: margin }} className={styles.container}>
        {step === 1 && (
          <Text>
            Please enter your company's name to access HivePix platform.
          </Text>
        )}
        <Form form={form} layout="vertical" requiredMark={false}>
          {step === 1 && (
            <FormItem
              label="Company"
              name="company"
              value={company}
              rules={[{ required: true, message: 'Enter company name' }]}
              hasFeedback
            >
              <Input size="large" suffix={!company ? undefined : <span />} />
            </FormItem>
          )}
          {step === 2 && (
            <>
              <FormItem
                label="Username"
                name="username"
                value={username}
                rules={[{ required: true, message: 'Fill in the field.' }]}
                hasFeedback
              >
                <Input size="large" suffix={!username ? undefined : <span />} />
              </FormItem>
              <FormItem
                label="Password"
                name="password"
                value={password}
                rules={[{ required: true, message: 'Fill in the field.' }]}
                hasFeedback
              >
                <Input.Password
                  size="large"
                  suffix={!password ? undefined : <span />}
                />
              </FormItem>
            </>
          )}
          {postError && (
            <ConfigProvider
              theme={{
                token: {
                  colorWarningBg: colorErrorBg,
                  colorWarningBorder: colorErrorBorder,
                },
              }}
            >
              <Alert
                style={{
                  marginBottom: margin,
                }}
                className={styles.error}
                showIcon
                message={postError}
                type="warning"
              />
            </ConfigProvider>
          )}
          <Button
            style={{
              marginTop: marginXS,
            }}
            className={styles.submit}
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={submit}
          >
            Proceed
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default SignInForm;

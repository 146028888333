import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconDashboard.module.scss';
import { TIconProps } from 'src/types/icons';

type IconDashboardProps = Omit<TIconProps, 'iconId'> & SVGProps<SVGSVGElement>;

export const IconDashboard: FunctionComponent<IconDashboardProps> = ({
  className,
  width = 16,
  height = 16,
  fill,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M8.80002 5.00002V2.98336C8.80002 2.81802 8.85591 2.67947 8.96769 2.56769C9.07958 2.45591 9.21814 2.40002 9.38336 2.40002H13.0099C13.1811 2.40002 13.3222 2.45591 13.4334 2.56769C13.5445 2.67947 13.6 2.81802 13.6 2.98336V5.00002C13.6 5.17002 13.5441 5.31253 13.4324 5.42753C13.3206 5.54253 13.182 5.60002 13.0167 5.60002H9.39019C9.21897 5.60002 9.0778 5.54253 8.96669 5.42753C8.85558 5.31253 8.80002 5.17002 8.80002 5.00002ZM2.40002 8.21669V2.98336C2.40002 2.81802 2.45775 2.67947 2.57319 2.56769C2.68864 2.45591 2.83169 2.40002 3.00236 2.40002H6.61669C6.77225 2.40002 6.90836 2.45591 7.02502 2.56769C7.14169 2.67947 7.20002 2.81802 7.20002 2.98336V8.21669C7.20002 8.37225 7.14169 8.50836 7.02502 8.62502C6.90836 8.74169 6.77225 8.80002 6.61669 8.80002H3.00236C2.83169 8.80002 2.68864 8.74169 2.57319 8.62502C2.45775 8.50836 2.40002 8.37225 2.40002 8.21669ZM8.80002 13V7.80002C8.80002 7.63002 8.85591 7.48752 8.96769 7.37252C9.07958 7.25752 9.21814 7.20002 9.38336 7.20002H13.0099C13.1811 7.20002 13.3222 7.25752 13.4334 7.37252C13.5445 7.48752 13.6 7.63002 13.6 7.80002V13C13.6 13.17 13.5441 13.3125 13.4324 13.4275C13.3206 13.5425 13.182 13.6 13.0167 13.6H9.39019C9.21897 13.6 9.0778 13.5425 8.96669 13.4275C8.85558 13.3125 8.80002 13.17 8.80002 13ZM2.40002 13V10.9667C2.40002 10.8014 2.45775 10.6628 2.57319 10.551C2.68864 10.4392 2.83169 10.3834 3.00236 10.3834H6.61669C6.77225 10.3834 6.90836 10.4392 7.02502 10.551C7.14169 10.6628 7.20002 10.8014 7.20002 10.9667V13C7.20002 13.17 7.14169 13.3125 7.02502 13.4275C6.90836 13.5425 6.77225 13.6 6.61669 13.6H3.00236C2.83169 13.6 2.68864 13.5425 2.57319 13.4275C2.45775 13.3125 2.40002 13.17 2.40002 13ZM3.60002 7.60002H6.00002V3.60002H3.60002V7.60002ZM10 12.4H12.4V8.40002H10V12.4ZM10 4.41669H12.4V3.60002H10V4.41669ZM3.60002 12.4H6.00002V11.5834H3.60002V12.4Z"
        fill={fill || 'currentColor'}
      />
    </svg>
  );
};

import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconBulb.module.scss';
import { TIconProps } from 'src/types/icons';

type IconBulbProps = Omit<TIconProps, 'iconId'> & SVGProps<SVGSVGElement>;

export const IconBulb: FunctionComponent<IconBulbProps> = ({
  className,
  width = 24,
  height = 24,
  fill,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M12 22.2C11.5667 22.2 11.1583 22.0959 10.775 21.8875C10.3917 21.6792 10.1083 21.3834 9.92499 21H9.59999C9.09999 21 8.67499 20.825 8.32499 20.475C7.97499 20.125 7.79999 19.7 7.79999 19.2V15.45C6.84999 14.7834 6.11249 13.9334 5.58749 12.9C5.06249 11.8667 4.79999 10.7667 4.79999 9.60002C4.79999 7.60002 5.49999 5.90002 6.89999 4.50002C8.29999 3.10002 9.99999 2.40002 12 2.40002C14 2.40002 15.7 3.10002 17.1 4.50002C18.5 5.90002 19.2 7.60002 19.2 9.60002C19.2 10.7667 18.9375 11.8667 18.4125 12.9C17.8875 13.9334 17.15 14.7834 16.2 15.45V19.2C16.2 19.7 16.025 20.125 15.675 20.475C15.325 20.825 14.9 21 14.4 21H14.05C13.8667 21.3834 13.5875 21.6792 13.2125 21.8875C12.8375 22.0959 12.4333 22.2 12 22.2ZM9.59999 19.2H14.4V18H9.59999V19.2ZM9.59999 16.8H14.4V15.6H9.59999V16.8ZM8.64999 13.8H11.4V11.05L9.59999 9.25002C9.48332 9.13336 9.42499 8.99169 9.42499 8.82502C9.42499 8.65836 9.48332 8.51669 9.59999 8.40002C9.71665 8.28336 9.85832 8.22502 10.025 8.22502C10.1917 8.22502 10.3333 8.28336 10.45 8.40002L12 9.95002L13.55 8.40002C13.6667 8.28336 13.8083 8.22502 13.975 8.22502C14.1417 8.22502 14.2833 8.28336 14.4 8.40002C14.5167 8.51669 14.575 8.65836 14.575 8.82502C14.575 8.99169 14.5167 9.13336 14.4 9.25002L12.6 11.05V13.8H15.35C15.9833 13.2834 16.4833 12.6625 16.85 11.9375C17.2167 11.2125 17.4 10.4334 17.4 9.60002C17.4 8.10002 16.875 6.82502 15.825 5.77502C14.775 4.72502 13.5 4.20002 12 4.20002C10.5 4.20002 9.22499 4.72502 8.17499 5.77502C7.12499 6.82502 6.59999 8.10002 6.59999 9.60002C6.59999 10.4167 6.78332 11.1917 7.14999 11.925C7.51665 12.6584 8.01665 13.2834 8.64999 13.8Z"
        fill={fill || 'currentColor'}
      />
    </svg>
  );
};

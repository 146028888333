import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconLogout.module.scss';
import { TIconProps } from 'src/types/icons';

type IconLogoutProps = Omit<TIconProps, 'iconId'> & SVGProps<SVGSVGElement>;

export const IconLogout: FunctionComponent<IconLogoutProps> = ({
  className,
  width = 24,
  height = 24,
  fill,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M5.40001 20.4C4.90501 20.4 4.48126 20.2237 4.12876 19.8712C3.77626 19.5187 3.60001 19.095 3.60001 18.6V5.39998C3.60001 4.90498 3.77626 4.48123 4.12876 4.12873C4.48126 3.77623 4.90501 3.59998 5.40001 3.59998H11.1C11.355 3.59998 11.5688 3.68573 11.7413 3.85723C11.9138 4.02873 12 4.24123 12 4.49472C12 4.74822 11.9138 4.96248 11.7413 5.13748C11.5688 5.31248 11.355 5.39998 11.1 5.39998H5.40001V18.6H11.1C11.355 18.6 11.5688 18.6857 11.7413 18.8572C11.9138 19.0287 12 19.2412 12 19.4947C12 19.7482 11.9138 19.9625 11.7413 20.1375C11.5688 20.3125 11.355 20.4 11.1 20.4H5.40001ZM16.95 12.9H10.499C10.2443 12.9 10.0308 12.8142 9.85851 12.6427C9.68617 12.4712 9.60001 12.2587 9.60001 12.0052C9.60001 11.7517 9.68617 11.5375 9.85851 11.3625C10.0308 11.1875 10.2443 11.1 10.499 11.1H16.95L15.55 9.69998C15.3667 9.51664 15.275 9.30356 15.275 9.06073C15.275 8.81789 15.3667 8.60539 15.55 8.42322C15.7333 8.24106 15.9458 8.14998 16.1875 8.14998C16.4292 8.14998 16.6417 8.24164 16.825 8.42498L19.775 11.375C19.9583 11.5523 20.05 11.7591 20.05 11.9955C20.05 12.2318 19.9583 12.4416 19.775 12.625L16.825 15.575C16.6417 15.7583 16.4333 15.8458 16.2 15.8375C15.9667 15.8291 15.7543 15.7333 15.563 15.55C15.3877 15.3666 15.3 15.1541 15.3 14.9125C15.3 14.6708 15.3917 14.4583 15.575 14.275L16.95 12.9Z"
        fill={fill || 'currentColor'}
      />
    </svg>
  );
};

export interface IProduct {
  id: string;
  name: string;
  materialNumber: string;
  partNumber: string;
  barCode: string;
  externalEpdUrl: string;
  canEdit: boolean;
  canDelete: boolean;
  epdFile: {
    blobName: string;
    fileName: string;
  };
  createdAt: Date;
  createdByName: string;
  modifiedAt: Date;
  modifiedByName: string;
}

export interface IAction {
  resource: string;
  action: string;
}

export enum Permission {
  ADMIN = 'Admin',
  ADVANCED_USER = 'AdvancedUser',
  BASIC_USER = 'BasicUser',
}

export interface IPermisson {
  name: Permission;
  permissions: IAction[];
}

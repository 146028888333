import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconArchive.module.scss';
import { TIconProps } from 'src/types/icons';

type IconArchiveProps = Omit<TIconProps, 'iconId'> & SVGProps<SVGSVGElement>;

export const IconArchive: FunctionComponent<IconArchiveProps> = ({
  className,
  width = 24,
  height = 24,
  fill,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M12 10C11.7167 10 11.4792 10.0958 11.2875 10.2875C11.0958 10.4792 11 10.7167 11 11V14.2L10.1 13.3C9.91667 13.1167 9.68333 13.025 9.4 13.025C9.11667 13.025 8.88333 13.1167 8.7 13.3C8.51667 13.4833 8.425 13.7167 8.425 14C8.425 14.2833 8.51667 14.5167 8.7 14.7L11.3 17.3C11.5 17.5 11.7333 17.6 12 17.6C12.2667 17.6 12.5 17.5 12.7 17.3L15.3 14.7C15.4833 14.5167 15.575 14.2833 15.575 14C15.575 13.7167 15.4833 13.4833 15.3 13.3C15.1167 13.1167 14.8833 13.025 14.6 13.025C14.3167 13.025 14.0833 13.1167 13.9 13.3L13 14.2V11C13 10.7167 12.9042 10.4792 12.7125 10.2875C12.5208 10.0958 12.2833 10 12 10ZM5 8V19H19V8H5ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V6.525C3 6.29167 3.0375 6.06667 3.1125 5.85C3.1875 5.63333 3.3 5.43333 3.45 5.25L4.7 3.725C4.88333 3.49167 5.1125 3.3125 5.3875 3.1875C5.6625 3.0625 5.95 3 6.25 3H17.75C18.05 3 18.3375 3.0625 18.6125 3.1875C18.8875 3.3125 19.1167 3.49167 19.3 3.725L20.55 5.25C20.7 5.43333 20.8125 5.63333 20.8875 5.85C20.9625 6.06667 21 6.29167 21 6.525V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5.4 6H18.6L17.75 5H6.25L5.4 6Z"
        fill={fill || 'currentColor'}
      />
    </svg>
  );
};

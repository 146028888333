import { useRef } from 'react';
import { SortOrder } from 'src/components/icons/sort/IconSort';
import {
  HTTP,
  TResponse,
  useNetworkContext,
} from 'src/contexts/network-context';
import { TGetProductsParams, TGetProductsResponse } from 'src/types/backend';

export type TDeleteProduct = (productId: string) => Promise<void>;
export type TGetProducts = (
  data: TGetProductsParams
) => Promise<TResponse<TGetProductsResponse>>;

const useProducts = () => {
  const { fetch } = useNetworkContext();

  const getProducts: TGetProducts = async (data: TGetProductsParams) => {
    const result = await fetch<TGetProductsResponse>({
      url: '/product/paged',
      method: HTTP.GET,
      params: {
        Page: data.pagination?.current,
        PageSize: data.pagination?.pageSize,
        ...(data.sortField
          ? {
              SortBy: data.sortField,
              Descending: data.sortOrder === SortOrder.DESC,
            }
          : {}),
        Search: data.search,
      },
    });

    return result;
  };

  const deleteProduct: TDeleteProduct = async (productId: string) => {
    await fetch<TGetProductsResponse>({
      url: `/product/${productId}/delete`,
      method: HTTP.DELETE,
    });
  };

  return {
    getProducts,
    deleteProduct,
  };
};

export default useProducts;

import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconHive.module.scss';
import { TIconProps } from 'src/types/icons';

type IconHiveProps = Omit<TIconProps, 'iconId'> & SVGProps<SVGSVGElement>;

export const IconHive: FunctionComponent<IconHiveProps> = ({
  className,
  width = 24,
  height = 24,
  fill,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M8.04998 18.7H5.59998C5.27681 18.7 4.97939 18.625 4.70773 18.475C4.43589 18.325 4.21664 18.1084 4.04998 17.825L2.87498 15.825C2.70831 15.5417 2.62081 15.2334 2.61248 14.9C2.60414 14.5667 2.68331 14.2584 2.84998 13.975L4.02498 12L2.84998 10.025C2.68331 9.74003 2.59998 9.43128 2.59998 9.09878C2.59998 8.76627 2.68331 8.45836 2.84998 8.17502L4.02498 6.17502C4.19164 5.89169 4.41089 5.67502 4.68273 5.52502C4.95439 5.37502 5.25181 5.30002 5.57498 5.30002H8.02498L9.22498 3.27502C9.39164 2.99169 9.61089 2.77502 9.88273 2.62502C10.1544 2.47502 10.4518 2.40002 10.775 2.40002H13.225C13.5481 2.40002 13.8456 2.47502 14.1172 2.62502C14.3891 2.77502 14.6083 2.99169 14.775 3.27502L15.975 5.30002H18.425C18.7481 5.30002 19.0456 5.37502 19.3172 5.52502C19.5891 5.67502 19.8083 5.89169 19.975 6.17502L21.15 8.17502C21.3166 8.46002 21.4 8.76877 21.4 9.10127C21.4 9.43377 21.3166 9.74169 21.15 10.025L19.975 12L21.15 13.975C21.3166 14.2584 21.3958 14.5667 21.3875 14.9C21.3791 15.2334 21.2916 15.5417 21.125 15.825L19.95 17.825C19.7833 18.1084 19.5641 18.325 19.2922 18.475C19.0206 18.625 18.7231 18.7 18.4 18.7H15.95L14.775 20.7C14.6146 20.978 14.3996 21.1975 14.1297 21.3585C13.8599 21.5195 13.5583 21.6 13.225 21.6H10.775C10.4416 21.6 10.1401 21.5195 9.87023 21.3585C9.60056 21.1975 9.38548 20.978 9.22498 20.7L8.04998 18.7ZM15.975 11.1H18.425L19.6 9.10002L18.425 7.10002H15.975L14.775 9.10002L15.975 11.1ZM10.775 14H13.225L14.4 12L13.225 10H10.775L9.59998 12L10.775 14ZM10.775 8.20002H13.225L14.4 6.20002L13.225 4.20002H10.775L9.59998 6.20002L10.775 8.20002ZM9.22498 9.10002L8.02498 7.10002H5.57498L4.39998 9.10002L5.57498 11.1H8.02498L9.22498 9.10002ZM9.22498 14.9L8.04998 12.9H5.59998L4.39998 14.9L5.57498 16.9H8.04998L9.22498 14.9ZM10.775 15.8L9.59998 17.8L10.775 19.8H13.225L14.4 17.8L13.225 15.8H10.775ZM15.975 16.9H18.425L19.6 14.9L18.425 12.9H15.975L14.775 14.9L15.975 16.9Z"
        fill={fill || 'currentColor'}
      />
    </svg>
  );
};

import React from 'react';
import { theme } from 'antd';
import styles from './SignIn.module.scss';
import SignInModal from 'src/components/sign-in/modal/SignInModal';
import { BeeIllustration } from 'src/components/sign-in/illustration/BeeIllustration';
import { LogoOnly } from 'src/components/logo/logo-only/LogoOnly';

const SignIn: React.FC = () => {
  const {
    token: { colorBgLayout },
  } = theme.useToken();

  return (
    <div
      className={styles.container}
      style={{ backgroundColor: colorBgLayout }}
    >
      <div className={styles.modalContainer}>
        <SignInModal className={styles.modal}/>
        <LogoOnly width="184" height="186" className={styles.illustration1} />
      </div>
      <div className={styles.illustrationContainer}>
        <BeeIllustration className={styles.illustration2} />
      </div>
    </div>
  );
};

export default SignIn;

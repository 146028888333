import { ConfigProvider, theme } from 'antd';
import { PropsWithChildren } from 'react';
import themeStyles from 'src/styles/theme.module.scss';
import { StyleProvider } from '@ant-design/cssinjs';

export const AntdThemeProvider = ({ children }: PropsWithChildren) => {
  const {
    token: { margin },
  } = theme.useToken();
  return (
    <StyleProvider layer>
      <ConfigProvider
        theme={{
          components: {
            Pagination: {
              itemActiveBg: themeStyles.colorPrimary,
              colorText: themeStyles. paginationColorText,
            },
            Form: {
              itemMarginBottom: margin,
            },
            Button: {
              primaryColor: themeStyles.colorTextBase,
              colorTextLightSolid: themeStyles.colorTextBase,
            },
            Menu: {
              colorBgContainer: themeStyles.menuColorBgContainer,
              itemSelectedBg: themeStyles.menuColorItemBgSelected,
              colorPrimary: themeStyles.menuColorPrimary,
            },
            Layout: {
              siderBg: themeStyles.colorPrimary,
            },
          },
          token: {
            colorTextBase: themeStyles.colorTextBase,
            colorPrimary: themeStyles.colorPrimary,
            colorPrimaryBg: themeStyles.colorPrimaryBg,
            colorPrimaryBgHover: themeStyles.colorPrimaryBgHover,
            colorPrimaryBorder: themeStyles.colorPrimaryBorder,
            colorPrimaryBorderHover: themeStyles.colorPrimaryBorderHover,
            colorPrimaryHover: themeStyles.colorPrimaryHover,
            colorPrimaryActive: themeStyles.colorPrimaryActive,
            colorPrimaryTextHover: themeStyles.colorPrimaryTextHover,
            colorPrimaryText: themeStyles.colorPrimaryText,
            colorPrimaryTextActive: themeStyles.colorPrimaryTextActive,
            colorLink: themeStyles.colorLink,
            colorLinkHover: themeStyles.colorLinkHover,
            colorLinkActive: themeStyles.colorLinkActive,
            colorBgMask: themeStyles.colorBgMask,
          },
        }}
      >
        {children}
      </ConfigProvider>
    </StyleProvider>
  );
};

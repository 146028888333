import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './Illustration.module.scss';
import { TIconProps } from 'src/types/icons';

type SiderIllustrationProps = Omit<TIconProps, 'iconId'> & SVGProps<SVGSVGElement>;

export const SiderIllustration: FunctionComponent<SiderIllustrationProps> = ({
  className,
  width = 200,
  height = 252,
  stroke,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 200 252"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M28.9689 81.75C29.7728 83.1423 29.7728 84.8577 28.9689 86.25L17.433 106.231C16.6292 107.623 15.1436 108.481 13.5359 108.481L-9.53589 108.481C-11.1436 108.481 -12.6292 107.623 -13.433 106.231L-24.9689 86.25C-25.7728 84.8577 -25.7728 83.1423 -24.9689 81.75L-13.433 61.7692C-12.6292 60.3769 -11.1436 59.5192 -9.53589 59.5192L13.5359 59.5192C15.1436 59.5192 16.6292 60.3769 17.433 61.7692L28.9689 81.75Z"
        stroke={stroke || 'currentColor'}
        strokeWidth="3"
      />
      <path
        d="M28.9689 137.712C29.7728 139.104 29.7728 140.819 28.9689 142.212L17.433 162.192C16.6292 163.585 15.1436 164.442 13.5359 164.442L-9.53589 164.442C-11.1436 164.442 -12.6292 163.585 -13.433 162.192L-24.9689 142.212C-25.7728 140.819 -25.7728 139.104 -24.9689 137.712L-13.433 117.731C-12.6292 116.338 -11.1436 115.481 -9.53589 115.481L13.5359 115.481C15.1436 115.481 16.6292 116.338 17.433 117.731L28.9689 137.712Z"
        stroke={stroke || 'currentColor'}
        strokeWidth="3"
      />
      <path
        d="M28.9689 193.673C29.7728 195.065 29.7728 196.781 28.9689 198.173L17.433 218.154C16.6292 219.546 15.1436 220.404 13.5359 220.404L-9.53589 220.404C-11.1436 220.404 -12.6292 219.546 -13.433 218.154L-24.9689 198.173C-25.7728 196.781 -25.7728 195.065 -24.9689 193.673L-13.433 173.692C-12.6292 172.3 -11.1436 171.442 -9.53589 171.442L13.5359 171.442C15.1436 171.442 16.6292 172.3 17.433 173.692L28.9689 193.673Z"
        stroke={stroke || 'currentColor'}
        strokeWidth="3"
      />
      <path
        d="M77.9689 109.731C78.7728 111.123 78.7728 112.838 77.9689 114.231L66.433 134.212C65.6292 135.604 64.1436 136.462 62.5359 136.462L39.4641 136.462C37.8564 136.462 36.3708 135.604 35.567 134.212L24.0311 114.231C23.2272 112.838 23.2272 111.123 24.0311 109.731L35.567 89.75C36.3708 88.3577 37.8564 87.5 39.4641 87.5L62.5359 87.5C64.1436 87.5 65.6292 88.3577 66.433 89.75L77.9689 109.731Z"
        stroke={stroke || 'currentColor'}
        strokeWidth="3"
      />
      <path
        d="M77.9689 165.692C78.7728 167.085 78.7728 168.8 77.9689 170.192L66.433 190.173C65.6292 191.565 64.1436 192.423 62.5359 192.423L39.4641 192.423C37.8564 192.423 36.3708 191.565 35.567 190.173L24.0311 170.192C23.2272 168.8 23.2272 167.085 24.0311 165.692L35.567 145.712C36.3708 144.319 37.8564 143.462 39.4641 143.462L62.5359 143.462C64.1436 143.462 65.6292 144.319 66.433 145.712L77.9689 165.692Z"
        stroke={stroke || 'currentColor'}
        strokeWidth="3"
      />
      <path
        d="M126.969 137.731C127.773 139.123 127.773 140.838 126.969 142.231L115.433 162.212C114.629 163.604 113.144 164.462 111.536 164.462L88.4641 164.462C86.8564 164.462 85.3709 163.604 84.567 162.212L73.0311 142.231C72.2273 140.838 72.2273 139.123 73.0311 137.731L84.567 117.75C85.3709 116.358 86.8564 115.5 88.4641 115.5L111.536 115.5C113.144 115.5 114.629 116.358 115.433 117.75L126.969 137.731Z"
        stroke={stroke || 'currentColor'}
        strokeWidth="3"
      />
      <path
        d="M126.969 193.692C127.773 195.085 127.773 196.8 126.969 198.192L115.433 218.173C114.629 219.565 113.144 220.423 111.536 220.423L88.4641 220.423C86.8564 220.423 85.3709 219.565 84.567 218.173L73.0311 198.192C72.2273 196.8 72.2273 195.085 73.0311 193.692L84.567 173.712C85.3709 172.319 86.8564 171.462 88.4641 171.462L111.536 171.462C113.144 171.462 114.629 172.319 115.433 173.712L126.969 193.692Z"
        stroke={stroke || 'currentColor'}
        strokeWidth="3"
      />
      <path
        d="M175.969 109.731C176.773 111.123 176.773 112.838 175.969 114.231L164.433 134.212C163.629 135.604 162.144 136.462 160.536 136.462L137.464 136.462C135.856 136.462 134.371 135.604 133.567 134.212L122.031 114.231C121.227 112.838 121.227 111.123 122.031 109.731L133.567 89.75C134.371 88.3577 135.856 87.5 137.464 87.5L160.536 87.5C162.144 87.5 163.629 88.3577 164.433 89.75L175.969 109.731Z"
        stroke={stroke || 'currentColor'}
        strokeWidth="3"
      />
      <path
        d="M175.969 165.692C176.773 167.085 176.773 168.8 175.969 170.192L164.433 190.173C163.629 191.565 162.144 192.423 160.536 192.423L137.464 192.423C135.856 192.423 134.371 191.565 133.567 190.173L122.031 170.192C121.227 168.8 121.227 167.085 122.031 165.692L133.567 145.712C134.371 144.319 135.856 143.462 137.464 143.462L160.536 143.462C162.144 143.462 163.629 144.319 164.433 145.712L175.969 165.692Z"
        stroke={stroke || 'currentColor'}
        strokeWidth="3"
      />
      <path
        d="M224.969 137.712C225.773 139.104 225.773 140.819 224.969 142.212L213.433 162.192C212.629 163.585 211.144 164.442 209.536 164.442L186.464 164.442C184.856 164.442 183.371 163.585 182.567 162.192L171.031 142.212C170.227 140.819 170.227 139.104 171.031 137.712L182.567 117.731C183.371 116.338 184.856 115.481 186.464 115.481L209.536 115.481C211.144 115.481 212.629 116.338 213.433 117.731L224.969 137.712Z"
        stroke={stroke || 'currentColor'}
        strokeWidth="3"
      />
    </svg>
  );
};

import { Typography, theme } from 'antd';
import styles from './SignInModal.module.scss';
import SignInModalHeader from './header/SignInModalHeader';
import useLoginForm from 'src/hooks/use-login-form';
import SignInForm from '../form/SignInForm';
import { Logo } from 'src/components/logo/Logo';
import clsx from 'clsx';

const { Title } = Typography;

type TSignInModalProps = {
  className?: string;
};

const SignInModal = ({ className }: TSignInModalProps) => {
  const {
    token: {
      colorBgContainer,
      boxShadowTertiary,
      borderRadiusLG,
      paddingXL,
      paddingLG,
      marginXS,
    },
  } = theme.useToken();

  const {
    step,
    setStep,
    loading,
    postError,
    form,
    company,
    username,
    password,
    submit,
  } = useLoginForm();

  return (
    <div
      className={clsx(styles.container, className)}
      style={{
        backgroundColor: colorBgContainer,
        boxShadow: boxShadowTertiary,
        borderRadius: borderRadiusLG,
        gap: paddingLG,
        padding: paddingXL,
      }}
    >
      <SignInModalHeader step={step} setStep={setStep} />
      <div>
        <Title level={3} className={styles.title}>
          Welcome to
        </Title>
        <Logo
          style={{
            marginTop: -marginXS,
            marginBottom: 18,
          }}
          width="188"
          height="47"
        />
        <SignInForm
          step={step}
          form={form}
          company={company}
          username={username}
          password={password}
          submit={submit}
          postError={postError}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default SignInModal;

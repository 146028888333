import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import AuthWrapper from 'src/components/auth-wrapper/AuthWrapper';
import GeneralLayout from 'src/components/layout/Layout';
import { ModalContextProvider } from 'src/contexts/modal-context';
import { NetworkContextProvider } from 'src/contexts/network-context';
import { UserContextProvider } from 'src/contexts/user-context';
import ProductList from 'src/pages/product-list/ProductList';
import SignIn from 'src/pages/sign-in/SignIn';
import 'src/styles/global.scss';
import { Routes, RouteTitle, ProtectedRoutes } from 'src/types/routes';

const router = createBrowserRouter([
  {
    element: (
      <UserContextProvider>
        <NetworkContextProvider>
          <AuthWrapper>
            <ModalContextProvider>
              <Outlet />
            </ModalContextProvider>
          </AuthWrapper>
        </NetworkContextProvider>
      </UserContextProvider>
    ),
    children: [
      {
        element: (
          <GeneralLayout>
            <Outlet />
          </GeneralLayout>
        ),
        children: [
          {
            element: <span>{RouteTitle[ProtectedRoutes.DASHBOARD]}</span>,
            path: ProtectedRoutes.DASHBOARD,
          },
          {
            element: <span>{RouteTitle[ProtectedRoutes.HIVE]}</span>,
            path: ProtectedRoutes.HIVE,
          },
          {
            element: <span>{RouteTitle[ProtectedRoutes.SUPPLIERS]}</span>,
            path: ProtectedRoutes.SUPPLIERS,
          },
          {
            element: <span>{RouteTitle[ProtectedRoutes.CUSTOMERS]}</span>,
            path: ProtectedRoutes.CUSTOMERS,
          },
          {
            element: <ProductList />,
            path: ProtectedRoutes.PRODUCTS,
          },
          {
            element: <span>{RouteTitle[ProtectedRoutes.INSIGHTS]}</span>,
            path: ProtectedRoutes.INSIGHTS,
          },
        ],
      },
      { path: Routes.SIGNIN, element: <SignIn /> },
      { path: '*', element: <p>404 not found</p> },
    ],
  },
]);

const AppRouterProvider = () => {
  return (
    <div id="app">
      <RouterProvider router={router} />
    </div>
  );
};

export default AppRouterProvider;

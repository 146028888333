import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconError.module.scss';
import { TIconProps } from 'src/types/icons';

type IconErrorProps = Omit<TIconProps, 'iconId'> &
  SVGProps<SVGSVGElement>;

export const IconError: FunctionComponent<IconErrorProps> = ({
  className,
  width = 16,
  height = 16,
  fill,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M7.99648 11.2C8.16548 11.2 8.30831 11.1428 8.42498 11.0285C8.54164 10.9141 8.59998 10.7725 8.59998 10.6035C8.59998 10.4345 8.54281 10.2916 8.42848 10.175C8.31414 10.0583 8.17248 9.99998 8.00348 9.99998C7.83448 9.99998 7.69164 10.0571 7.57498 10.1715C7.45831 10.2858 7.39998 10.4275 7.39998 10.5965C7.39998 10.7655 7.45714 10.9083 7.57148 11.025C7.68581 11.1416 7.82748 11.2 7.99648 11.2ZM7.99648 8.79998C8.16548 8.79998 8.30831 8.74248 8.42498 8.62748C8.54164 8.51248 8.59998 8.36998 8.59998 8.19998V5.39998C8.59998 5.22998 8.54281 5.08748 8.42848 4.97248C8.31414 4.85748 8.17248 4.79998 8.00348 4.79998C7.83448 4.79998 7.69164 4.85748 7.57498 4.97248C7.45831 5.08748 7.39998 5.22998 7.39998 5.39998V8.19998C7.39998 8.36998 7.45714 8.51248 7.57148 8.62748C7.68581 8.74248 7.82748 8.79998 7.99648 8.79998ZM8.00464 14.4C7.12375 14.4 6.29442 14.2333 5.51664 13.9C4.73886 13.5666 4.05831 13.1083 3.47498 12.525C2.89164 11.9416 2.43331 11.2613 2.09998 10.484C1.76664 9.70664 1.59998 8.87609 1.59998 7.99231C1.59998 7.10853 1.76664 6.28053 2.09998 5.50831C2.43331 4.73609 2.89164 4.05831 3.47498 3.47498C4.05831 2.89164 4.73864 2.43331 5.51598 2.09998C6.29331 1.76664 7.12386 1.59998 8.00764 1.59998C8.89142 1.59998 9.71942 1.76664 10.4916 2.09998C11.2639 2.43331 11.9416 2.89164 12.525 3.47498C13.1083 4.05831 13.5666 4.73731 13.9 5.51198C14.2333 6.28675 14.4 7.11453 14.4 7.99531C14.4 8.8762 14.2333 9.70553 13.9 10.4833C13.5666 11.2611 13.1083 11.9416 12.525 12.525C11.9416 13.1083 11.2626 13.5666 10.488 13.9C9.7132 14.2333 8.88542 14.4 8.00464 14.4Z"
        fill={fill || 'currentColor'}
      />
    </svg>
  );
};

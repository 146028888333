import { Typography } from 'antd';
import styles from './Url.module.scss';
import { IconOpen } from 'src/components/icons/open/IconOpen';

const { Link } = Typography;

type TUrlProps = {
  value: string;
};

const Url = ({ value }: TUrlProps) => {
  return (
    <div className={styles.component}>
      <Link className={styles.text} href={value} target='_blank'>{value}</Link>
      <IconOpen width="16" height="16" className={styles.icon} />
    </div>
  );
};

export default Url;

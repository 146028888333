import React, { ReactElement, useMemo, useState } from 'react';
import { Button, Layout, Menu, theme } from 'antd';
import styles from './NavigationBar.module.scss';
import clsx from 'clsx';
import { LogoOnly } from 'src/components/logo/logo-only/LogoOnly';
import { Logo } from 'src/components/logo/Logo';
import { IconBulb } from 'src/components/icons/bulb/IconBulb';
import { IconCampaign } from 'src/components/icons/campaign/IconCampaign';
import { IconChevronLeft } from 'src/components/icons/chevron-left/IconChevronLeft';
import { IconDashboard } from 'src/components/icons/dashboard/IconDashboard';
import { IconHandshake } from 'src/components/icons/handshake/IconHandshake';
import { IconHive } from 'src/components/icons/hive/IconHive';
import { IconHub } from 'src/components/icons/hub/IconHub';
import { IconInventory } from 'src/components/icons/inventory/IconInventory';
import { IconLogout } from 'src/components/icons/logout/IconLogout';
import { IconNature } from 'src/components/icons/nature/IconNature';
import { SiderIllustration } from '../illustration/Illustration';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProtectedRoutes } from 'src/types/routes';
import { UserContextActions, useUserContext } from 'src/contexts/user-context';
import { IconBee } from 'src/components/icons/bee/IconBee';
import { ItemType, MenuItemType } from 'antd/es/menu/interface';

const { Sider } = Layout;

const NavigationBar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { padding, paddingMD, sizeMD, boxShadowTertiary },
  } = theme.useToken();

  const sizeMDString = `${sizeMD}`;

  const {
    userContextState: { isAdmin, isAdvancedUser },
    userContextDispatch,
  } = useUserContext();

  const navigate = useNavigate();
  const location = useLocation();

  const defaultSelectedKeys = useMemo(() => {
    switch (location.pathname as ProtectedRoutes) {
      case ProtectedRoutes.DASHBOARD:
        return ['1'];
      case ProtectedRoutes.HIVE:
        return ['2'];
      case ProtectedRoutes.SUPPLIERS:
        return ['3'];
      case ProtectedRoutes.CUSTOMERS:
        return ['4'];
      case ProtectedRoutes.PRODUCTS:
        return ['5'];
      case ProtectedRoutes.INSIGHTS:
        return ['6'];
      default:
        return ['1'];
    }
  }, [location.pathname]);

  const adminRoute = (element: ItemType<MenuItemType>) =>
    isAdmin ? [element] : [];

  const adminAndAdvancedRoute = (element: ItemType<MenuItemType>) =>
    isAdmin || isAdvancedUser ? [element] : [];

  return (
    <div className={styles.container}>
      <Sider
        className={styles.sider}
        collapsedWidth={60}
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className={styles.elevated}>
          <div
            className={clsx(styles.logoContainer, {
              [styles.centered]: collapsed,
            })}
            style={{
              paddingLeft: padding,
              paddingRight: padding,
              paddingTop: paddingMD,
              paddingBottom: paddingMD,
            }}
          >
            {collapsed ? (
              <LogoOnly width="24" height="25" />
            ) : (
              <Logo width="96" height="18.5" />
            )}
          </div>
          <Menu
            inlineIndent={padding}
            mode="inline"
            defaultSelectedKeys={defaultSelectedKeys}
            items={[
              {
                key: '1',
                icon: (
                  <IconDashboard height={sizeMDString} width={sizeMDString} />
                ),
                label: 'Dashboard',
                onClick: () => {
                  navigate(ProtectedRoutes.DASHBOARD);
                },
              },
              {
                key: '2',
                icon: <IconHive height={sizeMDString} width={sizeMDString} />,
                label: 'My Hive',
                onClick: () => {
                  navigate(ProtectedRoutes.HIVE);
                },
              },
              ...adminRoute({
                key: '3',
                icon: <IconHub height={sizeMDString} width={sizeMDString} />,
                label: 'My Suppliers',
                onClick: () => {
                  navigate(ProtectedRoutes.SUPPLIERS);
                },
              }),
              ...adminAndAdvancedRoute({
                key: '4',
                icon: (
                  <IconHandshake height={sizeMDString} width={sizeMDString} />
                ),
                label: 'My Customers',
                onClick: () => {
                  navigate(ProtectedRoutes.CUSTOMERS);
                },
              }),
              {
                key: '5',
                icon: (
                  <IconInventory height={sizeMDString} width={sizeMDString} />
                ),
                label: 'My Products',
                onClick: () => {
                  navigate(ProtectedRoutes.PRODUCTS);
                },
              },
              {
                key: '6',
                icon: <IconBulb height={sizeMDString} width={sizeMDString} />,
                label: 'My Hive Insights',
                onClick: () => {
                  navigate(ProtectedRoutes.INSIGHTS);
                },
              },
            ]}
          />
        </div>

        {!collapsed && <SiderIllustration className={styles.illustration} />}
        <Menu
          inlineIndent={padding}
          selectable={false}
          mode="inline"
          items={[
            {
              key: '1',
              icon: <IconCampaign height={sizeMDString} width={sizeMDString} />,
              label: 'News and Support',
            },
            ...adminRoute({
              key: '2',
              icon: <IconNature height={sizeMDString} width={sizeMDString} />,
              label: 'Hive Control Centre',
            }),
            {
              key: '3',
              icon: <IconLogout height={sizeMDString} width={sizeMDString} />,
              label: 'Sign out',
              onClick: () => {
                userContextDispatch({
                  type: UserContextActions.LOGOUT,
                });
              },
            },
          ]}
        />
      </Sider>
      <Button
        type="default"
        icon={<IconChevronLeft />}
        onClick={() => setCollapsed(!collapsed)}
        className={clsx(styles.collapse, {
          [styles.rotate]: collapsed,
        })}
        style={{ boxShadow: boxShadowTertiary }}
      />
      <IconBee
        width="104"
        height="104"
        className={clsx(styles.bee, {
          [styles.rotate]: collapsed,
        })}
      />
    </div>
  );
};

export default NavigationBar;

import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconChevronLeft.module.scss';
import { TIconProps } from 'src/types/icons';

type IconChevronLeftProps = Omit<TIconProps, 'iconId'> &
  SVGProps<SVGSVGElement>;

export const IconChevronLeft: FunctionComponent<IconChevronLeftProps> = ({
  className,
  width = 16,
  height = 16,
  fill,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M7.29998 8.00007L10.0333 10.7334C10.1555 10.8556 10.2166 10.9973 10.2166 11.1584C10.2166 11.3195 10.1555 11.4612 10.0333 11.5834C9.91109 11.7056 9.76942 11.7667 9.60831 11.7667C9.4472 11.7667 9.30364 11.7037 9.17764 11.5777L6.01664 8.41673C5.96109 8.35673 5.91942 8.29173 5.89164 8.22173C5.86386 8.15173 5.84998 8.07673 5.84998 7.99673C5.84998 7.91673 5.86386 7.84173 5.89164 7.77173C5.91942 7.70173 5.96109 7.63895 6.01664 7.5834L9.17764 4.4224C9.30364 4.2964 9.4472 4.2334 9.60831 4.2334C9.76942 4.2334 9.91109 4.29451 10.0333 4.41673C10.1555 4.53895 10.2166 4.68062 10.2166 4.84173C10.2166 5.00284 10.1555 5.14451 10.0333 5.26673L7.29998 8.00007Z"
        fill={fill || 'currentColor'}
      />
    </svg>
  );
};

import { ProtectedRoutes, Routes } from 'src/types/routes';

export const LOGIN_PATHS: string[] = [Routes.SIGNIN];

export const PROTECTED_PATHS: string[] = [
  ProtectedRoutes.DASHBOARD,
  ProtectedRoutes.HIVE,
  ProtectedRoutes.SUPPLIERS,
  ProtectedRoutes.CUSTOMERS,
  ProtectedRoutes.PRODUCTS,
  ProtectedRoutes.INSIGHTS,
];

import { Empty, Table } from 'antd';
import { ProtectedRoutes, RouteTitle } from 'src/types/routes';
import { IconUpload } from 'src/components/icons/upload/IconUpload';
import { IconAdd } from 'src/components/icons/add/IconAdd';
import TableHeader from 'src/components/table/header/TableHeader';
import useProductsList from 'src/hooks/use-products-list';

const ProductList = () => {
  const {
    setSearch,
    columns,
    handleTableChange,
    loading,
    width,
    data,
    total,
    debouncedSearch,
  } = useProductsList();
  return (
    <div>
      <TableHeader
        title={RouteTitle[ProtectedRoutes.PRODUCTS]}
        searchPlaceholder={'Product name, number, code'}
        setSeach={setSearch}
        primaryActionTitle={'Import Product'}
        primaryActionIcon={<IconUpload width="16" height="16" />}
        primaryAction={function (
          event: React.MouseEvent<HTMLElement, MouseEvent>
        ) {}}
        secondaryActionTitle={'Add Product'}
        secondaryActionIcon={<IconAdd width="16" height="16" />}
        secondaryAction={function (
          event: React.MouseEvent<HTMLElement, MouseEvent>
        ) {}}
      />
      <Table
        bordered
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                debouncedSearch ? 'No results found' : 'No products added'
              }
            />
          ),
        }}
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
        loading={loading}
        scroll={{ x: width, y: 'calc(100vh - 264px)' }}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showQuickJumper: true,
          total: total,
        }}
      />
    </div>
  );
};

export default ProductList;

import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconInventory.module.scss';
import { TIconProps } from 'src/types/icons';

type IconInventoryProps = Omit<TIconProps, 'iconId'> & SVGProps<SVGSVGElement>;

export const IconInventory: FunctionComponent<IconInventoryProps> = ({
  className,
  width = 24,
  height = 24,
  fill,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M5.40002 21.6C4.90502 21.6 4.48127 21.4238 4.12877 21.0713C3.77627 20.7188 3.60002 20.295 3.60002 19.8V9.50003C3.25002 9.36669 2.96252 9.14586 2.73752 8.83752C2.51252 8.52919 2.40002 8.18336 2.40002 7.80002V4.20002C2.40002 3.70502 2.57627 3.28128 2.92877 2.92878C3.28127 2.57628 3.70502 2.40002 4.20002 2.40002H19.8C20.295 2.40002 20.7188 2.57628 21.0713 2.92878C21.4238 3.28128 21.6 3.70502 21.6 4.20002V7.80002C21.6 8.18336 21.4875 8.52919 21.2625 8.83752C21.0375 9.14586 20.75 9.36669 20.4 9.50003V19.7965C20.4 20.2989 20.2238 20.725 19.8713 21.075C19.5188 21.425 19.095 21.6 18.6 21.6H5.40002ZM5.40002 9.60002V19.8H18.6V9.60002H5.40002ZM4.20002 7.80002H19.8V4.20002H4.20002V7.80002ZM10.5 13.8H13.5C13.755 13.8 13.9688 13.7143 14.1413 13.5428C14.3138 13.3713 14.4 13.1588 14.4 12.9053C14.4 12.6518 14.3138 12.4375 14.1413 12.2625C13.9688 12.0875 13.755 12 13.5 12H10.5C10.245 12 10.0313 12.0858 9.85878 12.2573C9.68628 12.4288 9.60002 12.6413 9.60002 12.8948C9.60002 13.1483 9.68628 13.3625 9.85878 13.5375C10.0313 13.7125 10.245 13.8 10.5 13.8Z"
        fill={fill || 'currentColor'}
      />
    </svg>
  );
};

import React, { PropsWithChildren } from 'react';
import { Layout, Typography, theme } from 'antd';
import styles from './Layout.module.scss';
import NavigationBar from './sider/NavigationBar';
import { useLocation } from 'react-router-dom';
import { ProtectedRoutes, RouteTitle } from 'src/types/routes';

const { Header, Content } = Layout;
const { Title } = Typography;

export type TGeneralLayoutProps = PropsWithChildren;

const GeneralLayout = ({ children }: TGeneralLayoutProps) => {
  const {
    token: {
      colorBgContainer,
      borderRadiusLG,
      marginLG,
      paddingLG,
      fontWeightStrong,
    },
  } = theme.useToken();

  const location = useLocation();
  const title = RouteTitle[location.pathname as ProtectedRoutes];

  return (
    <Layout className={styles.container}>
      <NavigationBar />
      <Layout>
        <Header
          className={styles.titleContainer}
          style={{ background: colorBgContainer }}
        >
          <Title
            className={styles.title}
            level={5}
          >
            {title}
          </Title>
        </Header>
        <Content
          style={{
            margin: marginLG,
            marginBottom: 0,
            padding: paddingLG,
            paddingBottom: 0,
            background: colorBgContainer,
            borderTopLeftRadius: borderRadiusLG,
            borderTopRightRadius: borderRadiusLG,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default GeneralLayout;

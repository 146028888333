import { useEffect } from 'react';

const useDebouncer = function <T>(
  callback: (value: T) => void,
  newValue: T,
  time = 400
) {
  useEffect(() => {
    const timer = setTimeout(() => {
      callback(newValue);
    }, time);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [newValue]);
};

export default useDebouncer;

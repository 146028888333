import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconInfo.module.scss';
import { TIconProps } from 'src/types/icons';

type IconInfoProps = Omit<TIconProps, 'iconId'> & SVGProps<SVGSVGElement>;

export const IconInfo: FunctionComponent<IconInfoProps> = ({
  className,
  width = 24,
  height = 24,
  fill,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M11.9948 16.8C12.2483 16.8 12.4625 16.7138 12.6375 16.5413C12.8125 16.3688 12.9 16.155 12.9 15.9V11.7C12.9 11.445 12.8143 11.2313 12.6428 11.0588C12.4713 10.8863 12.2588 10.8 12.0053 10.8C11.7518 10.8 11.5375 10.8863 11.3625 11.0588C11.1875 11.2313 11.1 11.445 11.1 11.7V15.9C11.1 16.155 11.1858 16.3688 11.3573 16.5413C11.5288 16.7138 11.7413 16.8 11.9948 16.8ZM11.9948 9.00002C12.2483 9.00002 12.4625 8.91428 12.6375 8.74278C12.8125 8.57128 12.9 8.35878 12.9 8.10528C12.9 7.85178 12.8143 7.63752 12.6428 7.46252C12.4713 7.28752 12.2588 7.20002 12.0053 7.20002C11.7518 7.20002 11.5375 7.28577 11.3625 7.45727C11.1875 7.62877 11.1 7.84127 11.1 8.09477C11.1 8.34827 11.1858 8.56252 11.3573 8.73752C11.5288 8.91252 11.7413 9.00002 11.9948 9.00002ZM12.007 21.6C10.6857 21.6 9.44169 21.35 8.27502 20.85C7.10836 20.35 6.08752 19.6625 5.21252 18.7875C4.33752 17.9125 3.65002 16.892 3.15002 15.726C2.65002 14.56 2.40002 13.3142 2.40002 11.9885C2.40002 10.6629 2.65002 9.42086 3.15002 8.26253C3.65002 7.10419 4.33752 6.08752 5.21252 5.21252C6.08752 4.33752 7.10802 3.65002 8.27402 3.15002C9.44002 2.65002 10.6859 2.40002 12.0115 2.40002C13.3372 2.40002 14.5792 2.65002 15.7375 3.15002C16.8959 3.65002 17.9125 4.33752 18.7875 5.21252C19.6625 6.08752 20.35 7.10602 20.85 8.26802C21.35 9.43019 21.6 10.6719 21.6 11.993C21.6 13.3144 21.35 14.5584 20.85 15.725C20.35 16.8917 19.6625 17.9125 18.7875 18.7875C17.9125 19.6625 16.894 20.35 15.732 20.85C14.5699 21.35 13.3282 21.6 12.007 21.6Z"
        fill={fill || 'currentColor'}
      />
    </svg>
  );
};

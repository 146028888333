import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconEdit.module.scss';
import { TIconProps } from 'src/types/icons';

type IconEditProps = Omit<TIconProps, 'iconId'> & SVGProps<SVGSVGElement>;

export const IconEdit: FunctionComponent<IconEditProps> = ({
  className,
  width = 24,
  height = 24,
  fill,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M5.39998 18.6H6.67498L16.05 9.22498L14.775 7.94998L5.39998 17.325V18.6ZM4.50448 20.4C4.25148 20.4 4.03748 20.3141 3.86248 20.1425C3.68748 19.971 3.59998 19.7583 3.59998 19.5045V17.3332C3.59998 17.0944 3.64164 16.8666 3.72498 16.65C3.80831 16.4333 3.94164 16.2333 4.12498 16.05L16.05 4.12498C16.2333 3.94164 16.432 3.80831 16.646 3.72498C16.8598 3.64164 17.0848 3.59998 17.321 3.59998C17.557 3.59998 17.7833 3.64164 18 3.72498C18.2166 3.80831 18.4166 3.94164 18.6 4.12498L19.875 5.39998C20.0583 5.58331 20.1916 5.78331 20.275 5.99998C20.3583 6.21664 20.4 6.43781 20.4 6.66348C20.4 6.90431 20.3581 7.13381 20.2745 7.35198C20.1908 7.57014 20.0576 7.76948 19.875 7.94998L7.94998 19.875C7.76664 20.0583 7.56706 20.1916 7.35123 20.275C7.13539 20.3583 6.90839 20.4 6.67023 20.4H4.50448ZM15.4012 8.59873L14.775 7.94998L16.05 9.22498L15.4012 8.59873Z"
        fill={fill || 'currentColor'}
      />
    </svg>
  );
};

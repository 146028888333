import { Button, Divider, Input, Typography } from 'antd';
import styles from './TableHeader.module.scss';
import { ReactNode } from 'react';
import { IconSearch } from 'src/components/icons/search/IconSearch';
import { useUserContext } from 'src/contexts/user-context';

const { Title } = Typography;

type TTableHeaderProps = {
  title: string;
  searchPlaceholder: string;
  setSeach: (value: string) => void;
  primaryActionTitle: string;
  primaryActionIcon?: ReactNode;
  primaryAction: (event: React.MouseEvent<HTMLElement, MouseEvent>) => unknown;
  secondaryActionTitle: string;
  secondaryActionIcon?: ReactNode;
  secondaryAction: (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => unknown;
};

const TableHeader = ({
  title,
  searchPlaceholder,
  setSeach,
  primaryActionTitle,
  primaryAction,
  primaryActionIcon,
  secondaryActionTitle,
  secondaryAction,
  secondaryActionIcon,
}: TTableHeaderProps) => {
  const {
    userContextState: { isAdmin, isAdvancedUser },
  } = useUserContext();

  const basicUser = !isAdmin && !isAdvancedUser;
  return (
    <div className={styles.component}>
      <Title className={styles.title} level={5}>
        {title}
      </Title>
      <div className={styles.controls}>
        <Input
          prefix={<IconSearch width="16" height="16" className={styles.icon} />}
          className={styles.search}
          placeholder={searchPlaceholder}
          onChange={(event) => {
            setSeach(event.target.value);
          }}
        />
        {!basicUser && (
          <>
            <Divider type="vertical" className={styles.divider} />
            <Button icon={secondaryActionIcon} onClick={secondaryAction}>
              {secondaryActionTitle}
            </Button>
            <Button
              icon={primaryActionIcon}
              type="primary"
              onClick={primaryAction}
            >
              {primaryActionTitle}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default TableHeader;

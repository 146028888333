import axios, {
  AxiosError,
  AxiosHeaders,
  AxiosResponse,
  ResponseType,
} from 'axios';
import {
  FunctionComponent,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useRef,
} from 'react';
import {
  REFRESH_TOKEN,
  UserContextActions,
  useUserContext,
} from './user-context';
import { TGetAccessTokenResponse } from 'src/types/backend';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'src/types/routes';

const API_URL = process.env.REACT_APP_API_URL;

export enum HTTP {
  POST = 'post',
  GET = 'get',
  PATCH = 'patch',
  DELETE = 'delete',
}

export type TFetchParams = {
  url: string;
  method?: HTTP;
  params?: unknown;
  data?: unknown;
  headers?: AxiosHeaders;
  responseType?: ResponseType;
  retry?: boolean;
};

export type TResponse<T> = {
  result: AxiosResponse<T> | null;
  errors: AxiosError | null;
};

export type TNetworkContext = {
  fetch: <T>(params: TFetchParams) => Promise<TResponse<T>>;
};

const NetworkContext = createContext<TNetworkContext>({
  fetch: () => {
    return new Promise(() => {});
  },
});

type NetworkContextProviderProps = PropsWithChildren;

export const NetworkContextProvider: FunctionComponent<
  NetworkContextProviderProps
> = ({ children }) => {
  const {
    userContextState: { accessToken },
    userContextDispatch,
  } = useUserContext();

  const isRefreshing = useRef(false);
  const refetchingQue = useRef<Array<(newToken: any) => Promise<void>>>([]);
  const processQueue = (token: string | null) => {
    refetchingQue.current.forEach((prom) => {
      if (token) {
        prom(token);
      } else {
        prom(null);
      }
    });

    refetchingQue.current = [];
  };

  const navigate = useNavigate();

  const fetch = async function <T>({
    url,
    method = HTTP.GET,
    responseType = 'json',
    params,
    headers,
    data,
    retry = true,
  }: TFetchParams) {
    try {
      const response = await axios({
        url: `${API_URL}${url}`,
        responseType,
        method,
        params,
        data,
        headers: {
          ...(accessToken
            ? {
                Authorization: `Bearer ${accessToken}`,
              }
            : {}),
          ...headers,
        },
      });
      return { result: response as T, errors: null } as TResponse<T>;
    } catch (error) {
      const axiosError = error as AxiosError;

      if (axiosError.status === 401 && retry) {
        if (!isRefreshing.current) {
          isRefreshing.current = true;
          const newAccessToken = await handleTokenRefresh();
          isRefreshing.current = false;

          if (newAccessToken) {
            processQueue(newAccessToken);
            const newHeaders = { ...headers };
            newHeaders['Authorization'] = `Bearer ${newAccessToken}`;
            const result: TResponse<T> = await fetch<T>({
              url,
              method,
              responseType,
              params,
              headers: newHeaders as AxiosHeaders,
              data,
              retry: false,
            });

            return result;
          }
        } else {
          const prom = new Promise<TResponse<T>>((resolve) => {
            refetchingQue.current.push(async (newToken) => {
              if (newToken) {
                const newHeaders = { ...headers };
                newHeaders['Authorization'] = `Bearer ${newToken}`;
                const result = await fetch<T>({
                  url,
                  method,
                  responseType,
                  params,
                  headers: newHeaders as AxiosHeaders,
                  data,
                  retry: false,
                });
                return resolve(result);
              } else {
                return resolve({ result: null, errors: axiosError });
              }
            });
          });
          return prom;
        }
      }

      return { result: null, errors: error as AxiosError } as TResponse<T>;
    }
  };

  const handleTokenRefresh = async (): Promise<string | null> => {
    const refreshToken = localStorage.getItem('refreshToken');

    if (!refreshToken) {
      return null;
    }

    try {
      const response: AxiosResponse<TGetAccessTokenResponse> = await axios.post(
        `${API_URL}/identity/refresh`,
        {
          refreshToken,
        }
      );

      const newAccessToken = response.data.accessToken;

      if (newAccessToken) {
        localStorage.setItem(REFRESH_TOKEN, response.data.refreshToken);
        userContextDispatch({
          type: UserContextActions.SET_USER,
          value: newAccessToken,
        });
        return newAccessToken;
      }
    } catch (error) {
      const axiosError = error as AxiosError;

      if (axiosError.status === 400) {
        console.log(
          `%c⏩\tUser is logged out. Redirecting to the sign in.`,
          'color: #876800;'
        );
        localStorage.removeItem(REFRESH_TOKEN);
        setTimeout(() => navigate(Routes.SIGNIN), 0);
      }
    }

    return null;
  };

  return (
    <NetworkContext.Provider value={{ fetch }}>
      {children}
    </NetworkContext.Provider>
  );
};

export const useNetworkContext = () => {
  const context = useContext(NetworkContext);

  if (!context) {
    throw new Error(
      '"NetworkContext" context is not in scope. Use this hook in a component that is wrapped with the <NetworkContextProvider /> component.'
    );
  }

  return context;
};

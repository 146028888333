import { Button, Typography, theme } from 'antd';
import styles from './Modal.module.scss';
import { CloseOutlined } from '@ant-design/icons';
import { IconBee } from '../icons/bee/IconBee';
import { useEffect, useRef } from 'react';

type TModalProps = {
  title: string;
  description: string;
  primaryActionTitle: string;
  primaryAction: () => void;
  primaryIsLoading: boolean;
  secondaryActionTitle?: string;
  secondaryAction: () => void;
};

const { Text } = Typography;

const Modal = ({
  title,
  description,
  primaryAction,
  primaryIsLoading,
  primaryActionTitle,
  secondaryAction,
  secondaryActionTitle,
}: TModalProps) => {
  const {
    token: { boxShadowTertiary },
  } = theme.useToken();

  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: MouseEvent) => {
    if (!ref.current?.contains(e.target as Node)) {
      secondaryAction();
    }
  };

  useEffect(() => {
    // Note: need timeout, so it won't be fired when modal is rendered
    setTimeout(() => {
      document.addEventListener('click', handleClickOutside, false);
    }, 0);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);
  return (
    <div className={styles.container}>
      <div
        className={styles.modal}
        style={{ boxShadow: boxShadowTertiary }}
        data-element="modal"
        ref={ref}
      >
        <Button
          type="text"
          className={styles.close}
          onClick={secondaryAction}
          icon={<CloseOutlined />}
        />
        <IconBee width="104" height="104" className={styles.bee} />
        <div className={styles.body}>
          <Text className={styles.title}>{title}</Text>
          <Text className={styles.description}>{description}</Text>
          <div className={styles.buttons}>
            <Button onClick={secondaryAction}>{secondaryActionTitle}</Button>
            <Button
              loading={primaryIsLoading}
              type="primary"
              onClick={primaryAction}
            >
              {primaryActionTitle}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;

import clsx from 'clsx';
import { type FunctionComponent, type SVGProps } from 'react';

import styles from './IconCampaign.module.scss';
import { TIconProps } from 'src/types/icons';

type IconCampaignProps = Omit<TIconProps, 'iconId'> & SVGProps<SVGSVGElement>;

export const IconCampaign: FunctionComponent<IconCampaignProps> = ({
  className,
  width = 24,
  height = 24,
  fill,
  ...props
}): JSX.Element => {
  return (
    <svg
      className={clsx(className, styles.component)}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-element="icon"
      {...props}
    >
      <path
        d="M21 12.9999H19C18.7167 12.9999 18.4792 12.9041 18.2875 12.7124C18.0958 12.5208 18 12.2833 18 11.9999C18 11.7166 18.0958 11.4791 18.2875 11.2874C18.4792 11.0958 18.7167 10.9999 19 10.9999H21C21.2833 10.9999 21.5208 11.0958 21.7125 11.2874C21.9042 11.4791 22 11.7166 22 11.9999C22 12.2833 21.9042 12.5208 21.7125 12.7124C21.5208 12.9041 21.2833 12.9999 21 12.9999ZM16.6 16.7999C16.7667 16.5666 16.9833 16.4333 17.25 16.3999C17.5167 16.3666 17.7667 16.4333 18 16.5999L19.6 17.7999C19.8333 17.9666 19.9667 18.1833 20 18.4499C20.0333 18.7166 19.9667 18.9666 19.8 19.1999C19.6333 19.4333 19.4167 19.5666 19.15 19.5999C18.8833 19.6333 18.6333 19.5666 18.4 19.3999L16.8 18.1999C16.5667 18.0333 16.4333 17.8166 16.4 17.5499C16.3667 17.2833 16.4333 17.0333 16.6 16.7999ZM19.6 6.19994L18 7.39994C17.7667 7.5666 17.5167 7.63327 17.25 7.59994C16.9833 7.5666 16.7667 7.43327 16.6 7.19994C16.4333 6.9666 16.3667 6.7166 16.4 6.44994C16.4333 6.18327 16.5667 5.9666 16.8 5.79993L18.4 4.59993C18.6333 4.43327 18.8833 4.3666 19.15 4.39993C19.4167 4.43327 19.6333 4.5666 19.8 4.79993C19.9667 5.03327 20.0333 5.28327 20 5.54993C19.9667 5.8166 19.8333 6.03327 19.6 6.19994ZM5 14.9999H4C3.45 14.9999 2.97917 14.8041 2.5875 14.4124C2.19583 14.0208 2 13.5499 2 12.9999V10.9999C2 10.4499 2.19583 9.9791 2.5875 9.58744C2.97917 9.19577 3.45 8.99994 4 8.99994H8L11.475 6.89994C11.8083 6.69994 12.1458 6.69994 12.4875 6.89994C12.8292 7.09994 13 7.3916 13 7.77494V16.2249C13 16.6083 12.8292 16.8999 12.4875 17.0999C12.1458 17.2999 11.8083 17.2999 11.475 17.0999L8 14.9999H7V17.9999C7 18.2833 6.90417 18.5208 6.7125 18.7124C6.52083 18.9041 6.28333 18.9999 6 18.9999C5.71667 18.9999 5.47917 18.9041 5.2875 18.7124C5.09583 18.5208 5 18.2833 5 17.9999V14.9999ZM11 14.4499V9.54994L8.55 10.9999H4V12.9999H8.55L11 14.4499ZM14 15.3499V8.64994C14.45 9.04994 14.8125 9.53744 15.0875 10.1124C15.3625 10.6874 15.5 11.3166 15.5 11.9999C15.5 12.6833 15.3625 13.3124 15.0875 13.8874C14.8125 14.4624 14.45 14.9499 14 15.3499Z"
        fill={fill || 'currentColor'}
      />
    </svg>
  );
};
